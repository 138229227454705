<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'8'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>设置</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>收货地址</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('1')">
          <span>闲赚海报</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>闲赚比例</span>
        </div>
        <div class="left-list-item" @click="leftClick('3')">
          <span>驳回原因</span>
        </div>
        <div class="left-list-item" @click="leftClick('4')">
          <span>客服</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>闲赚海报</span>
        </div>
        <div class="mt-20">
          <el-upload
            class="upload-demo"
            :action="$store.state.httpUrl + 'upd/dealer_poster'"
            name="dealer_poster"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="fileList"
            :headers="uploadHeaders"
            ref="my-upload"
            list-type="picture"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <span class="upload-title">图片尺寸：750*1200</span>
          </el-upload>
        </div>
        <div class="upload-img">
          <span v-if="!imageUrl">展示图片处</span>
          <img v-else :src="'http://weixin.maibaobao.shop/' + imageUrl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      fromList: {
        name: "",
      },
      fileList: [],
      imageUrl: "",
      uploadHeaders: {
        "Remember-Token": localStorage.getItem("rememberToken"),
      },
    };
  },
  created() {
    this.getImage();
  },
  methods: {
    getImage() {
      this.$get("get/dealer_poster", {}).then((res) => {
        console.log(res);
        this.imageUrl = res.data.data.backdrop.src;
      });
    },
    handleAvatarSuccess() {
      this.$refs["my-upload"].clearFiles();
      this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
      this.getImage();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/setup") {
        this.$router.push("/setup");
      } else if (index == 1 && this.$route.path != "/poster") {
        this.$router.push("/poster");
      } else if (index == 2 && this.$route.path != "/proportion") {
        this.$router.push("/proportion");
      } else if (index == 3 && this.$route.path != "/reject") {
        this.$router.push("/reject");
      } else if (index == 4 && this.$route.path != "/customer") {
        this.$router.push("/customer");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .upload-title {
    margin-left: 100px;
    font-size: 14px;
  }
  .upload-img {
    display: inline-block;
    width: 350px;
    margin-top: 20px;
    text-align: center;
    height: 500px;
    border: 1px dashed #8b8888;
    border-radius: 10px;
    overflow: hidden;
    span {
      display: inline-block;
      margin-top: 200px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
